<template>
	<configurations :help_text="help_text" class="ml-4" />
</template>

<script>
import Configurations   from '@/components/AsmConfigurations/Configurations';

import { mapActions }   from 'vuex';
import _ from 'lodash';

export default 
{
	components: {
		Configurations
	},
	data() {
		return {
			help_text: 'General CyberBullying Endpoint configuration. From this screen is possible to select the services that will be enabled at global level as well as general settings and data retention'
		}
	},
	beforeMount() {
		this.getAdvancedConfigurations();
	},
	methods: {
		...mapActions('ispServices/services', ['getAdvancedConfigurations'])
	}
}
</script>